/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./user.component";
import * as i5 from "../../../model/staff.service";
var styles_UserComponent = [i0.styles];
var RenderType_UserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserComponent, data: { "animation": [{ type: 7, name: "toggleUserMenu", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { height: "0", opacity: "0" }, offset: null }, options: undefined }, { type: 0, name: "true", styles: { type: 6, styles: { height: "*", opacity: "1" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "200ms ease-in" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "200ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_UserComponent as RenderType_UserComponent };
function View_UserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "user__menu"]], [[24, "@toggleUserMenu", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "dropdown-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var currVal_3 = _ck(_v, 3, 0, "/logout"); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userMenu; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_UserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "user__info"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.userMenu = !_co.userMenu) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "user__img"], ["src", "./assets/img/8.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "user__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "user__email"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.userMenu; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userMenu; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.userData.fullName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.userData.roleLabel; _ck(_v, 6, 0, currVal_2); }); }
export function View_UserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidebar-user", [], null, null, null, View_UserComponent_0, RenderType_UserComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserComponent, [i5.StaffService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserComponentNgFactory = i1.ɵccf("sidebar-user", i4.UserComponent, View_UserComponent_Host_0, {}, {}, []);
export { UserComponentNgFactory as UserComponentNgFactory };
