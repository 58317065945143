import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';

import * as _ from 'lodash';
import { Gallery } from './gallery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class GalleryDataService {
  static getGalleryAllKey: string = 'get-all-galleries';
  static getSearchOptionKey: string = 'get-all-galleries-search-options';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  static getLinkTypes(): Array<any> {
    return [
      {
        value: 'none',
        label: 'No link'
      },
      {
        value: '_self',
        label: 'In same window'
      },
      {
        value: '_blank',
        label: 'New window'
      }
    ];
  }

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/gallery
  getAllGalleries(): Observable<Gallery[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/gallery', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new Gallery(value))),
        catchError(GlobalService.handleError)
      );
  }

  getGalleryById(id: number): Observable<Gallery> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/gallery/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Gallery(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/gallery
  addGallery(gallery: Gallery): Observable<ResponseBody> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const formData = this.convertToFormData(gallery);

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/gallery', formData, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/gallery/1
  updateGalleryById(gallery: Gallery): Observable<ResponseBody> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const formData = this.convertToFormData(gallery);

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/gallery/' + gallery.id, formData, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  convertToFormData(gallery: Gallery) {
    const formData: FormData = new FormData();

    _.forIn(gallery, (value: any, key: string) => {
      if (key === 'gallery_image') {
        return;
      }
      formData.append(key, value !== null ? value : '');
    });

    if (gallery.gallery_image) {
      formData.append('gallery_image', gallery.gallery_image, gallery.gallery_image.name);
    }
    return formData;
  }

  // DELETE /v1/set/1
  deleteGalleryById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/gallery/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(GalleryDataService.getGalleryAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
