export class StudentUser {
  id: number;
  student_id: number;
  branch_id: number;
  branch_name: string;
  student_name: string;
  dob: string;
  email: string;
  password: string;
  confirmed_at: string;
  confirmed_at_utc: Date;
  registration_ip: string;
  last_login_at: string;
  last_login_ip: string;
  blocked_at: string;
  blocked_at_utc: Date;
  status: number;
  status_label: string;
  created_at: string;
  updated_at: string;

  constructor(values: Object = {}) {
    this.email = '';
    this.password = '';
    this.confirmed_at = null;
    this.blocked_at = null;
    this.status = 0;
    Object.assign(this, values);
  }
}
