import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-navigation-trigger',
  templateUrl: 'navigation-trigger.component.html',
  styleUrls: ['navigation-trigger.component.scss']
})
export class NavigationTriggerComponent implements OnInit {
  constructor(public sharedService: SharedService) {}

  openMobileSidebar() {
    this.sharedService.mobileSidebarActive = !this.sharedService.mobileSidebarActive;
  }

  ngOnInit() {}
}
