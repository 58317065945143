import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Staff } from './staff';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class StaffDataService {
  static getAllKey: string = 'get-all-staffs';

  static getStatusTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 10
      },
      {
        label: 'Waiting Confirmation',
        value: 1
      },
      {
        label: 'Disabled',
        value: 0
      }
    ];
  }

  static getRoleTypes(): Array<any> {
    return [
      {
        label: 'Administrator',
        value: 99
      },
      {
        label: 'HQ Staff',
        value: 50
      },
      {
        label: 'Branch Staff',
        value: 45
      }
    ];
  }

  static getRoleTypesBranchOnly(): Array<any> {
    return [
      {
        label: 'Branch Staff',
        value: 45
      }
    ];
  }

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/staff
  getAllStaffs(): Observable<Staff[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/staff?sort=-id', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new Staff(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/staff/1
  getStaffById(id: number): Observable<Staff> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/staff/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Staff(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/staff
  addStaff(staff: Staff): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(StaffDataService.getAllKey);

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/staff', JSON.stringify(staff), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/staff/1
  updateStaffById(staff: Staff): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(StaffDataService.getAllKey);

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/staff/' + staff.id, JSON.stringify(staff), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/staff/1
  deleteStaffById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(StaffDataService.getAllKey);

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/staff/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  getPermissionTypes(): Observable<Array<any>> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/staff/get-permissions', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError)
      );
  }
}
