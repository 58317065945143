export class PaymentGroupSet {
  id: number;
  row_num: number;
  payment_group_id: number;
  classroom_id: number;
  term_id: number;
  term_type: string;
  term_start: string;
  term_finish: string;
  total_module_no: number;
  course_id: number;
  course_name: string;
  grade_id: number;
  grade: string;
  teacher_id: number;
  teacher_name: string;
  day: string;
  location: string;
  class_no: string;
  module_no: number;
  test_document_type_id: number;
  test_document_type_code: string;
  test_document_type: string;
  number_of_sets: number;
  original_price: number;
  price: number;
  currency: string;
  order_by: number;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
