import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { PaymentMethod } from './payment-method';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class PaymentMethodDataService {
  static getAllKey: string = 'get-all-payment-methods';
  static getSearchOptionKey: string = 'get-all-payment-methods-search-option';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/payment-method
  getAllPaymentMethods(): Observable<PaymentMethod[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/payment-method?sort=order_by', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new PaymentMethod(value))),
        catchError(GlobalService.handleError)
      );
  }

  getPaymentMethodById(id: number): Observable<PaymentMethod> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/payment-method/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new PaymentMethod(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/payment-method
  addPaymentMethod(paymentMethod: PaymentMethod): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(PaymentMethodDataService.getAllKey);

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/payment-method', JSON.stringify(paymentMethod), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/payment-method/1
  updatePaymentMethodById(paymentMethod: PaymentMethod): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(PaymentMethodDataService.getAllKey);

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/payment-method/' + paymentMethod.id,
        JSON.stringify(paymentMethod),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/payment-method/1
  deletePaymentMethodById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(PaymentMethodDataService.getAllKey);

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/payment-method/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }
}
