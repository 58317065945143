import { TestSubCategory } from './test-sub-category';
import { Subject } from './subject';

export class TestMainCategory {
  id: number;
  subject_id: number;
  subject: Subject;
  main_category: string;
  sub_category: Array<TestSubCategory>;
  order_by: number;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  get subject_name(): string {
    return this.subject.subject_name;
  }
}
