import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Question } from './question';

import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { QuestionStatistic } from './question-statistic';

@Injectable()
export class QuestionDataService {
  static getAllKey: string = 'get-all-questions';
  static getKey: string = 'get-question';
  static queryParamsKey: string = 'question-list-query-params';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  static getQuestionAnswerTypes(): Array<any> {
    return [
      {
        label: 'Single Choice',
        value: 'single'
      },
      {
        label: 'Essay',
        value: 'essay'
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/question
  getAllQuestions(): Observable<Question[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/question?sort=-id', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new Question(value))),
        catchError(GlobalService.handleError)
      );
  }

  getQuestionById(id: number): Observable<Question> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/question/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Question(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  convertToFormData(question: Question) {
    const formData: FormData = new FormData();

    _.forIn(question, (value: any, key: string) => {
      if (key === 'question_image' || key === 'question_group_article_image') {
        return;
      }
      formData.append(key, value !== null ? value : '');
    });

    if (question.question_image) {
      formData.append('question_image', question.question_image, question.question_image.name);
    }
    if (question.question_group_article_image) {
      formData.append(
        'question_group_article_image',
        question.question_group_article_image,
        question.question_group_article_image.name
      );
    }
    return formData;
  }

  // POST /v1/question
  addQuestion(question: Question): Observable<ResponseBody> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const formData = this.convertToFormData(question);
    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/question', formData, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/question/1
  updateQuestionById(question: Question): Observable<ResponseBody> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const formData = this.convertToFormData(question);
    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/question/' + question.id, formData, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/question/1
  deleteQuestionById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/question/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/question/1/question-statistics
  getQuestionStatisticsById(id: number): Observable<QuestionStatistic[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/question/' + id + '/question-statistics', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new QuestionStatistic(value))),
        catchError(GlobalService.handleError)
      );
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/question';
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(QuestionDataService.getAllKey)) {
        localStorage.removeItem(key);
      }

      if (key.startsWith(QuestionDataService.getKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
