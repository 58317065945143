export class PaymentGroup {
  id: number;
  row_num: number;
  classroom_id: number;
  term_id: number;
  term_type: string;
  term_start: string;
  term_finish: string;
  total_module_no: number;
  course_id: number;
  course_name: string;
  grade_id: number;
  grade: string;
  teacher_id: number;
  teacher_name: string;
  day: string;
  location: string;
  class_no: string;
  tuition_fee: number;
  material_fee: number;
  start_date: string;
  start_date_utc: Date;
  finish_date: string;
  finish_date_utc: Date;
  class_date_range: any[];
  early_bird_date: string;
  early_bird_date_utc: Date;
  module_no: number;
  start_time: string;
  start_time_utc: Date;
  finish_time: string;
  finish_time_utc: Date;
  enrolled_student_cnt: number;
  dropped_student_cnt: number;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    this.classroom_id = null;
    Object.assign(this, values);
  }
}
