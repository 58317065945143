import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Term } from './term';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class TermDataService {
  static getAllKey: string = 'get-all-terms';
  static getSearchOptionKey: string = 'get-all-terms-search-option';
  static getTermTypeAllKey: string = 'get-all-term-types';
  static getTermTypeSearchOptionKey: string = 'get-all-term-types-search-options';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/term
  getAllTerms(): Observable<Term[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/term?sort=year,term_start', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new Term(value))),
        catchError(GlobalService.handleError)
      );
  }

  getTermById(id: number): Observable<Term> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/term/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Term(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/term
  addTerm(term: Term): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/term', JSON.stringify(term), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/term/1
  updateTermById(term: Term): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/term/' + term.id, JSON.stringify(term), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/term/1
  deleteTermById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/term/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(TermDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith(TermDataService.getTermTypeAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
