import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-layout',
  templateUrl: './simple-layout.component.html'
})
export class SimpleLayoutComponent implements OnInit, OnDestroy {
  subscribePoll: any = {};
  maTheme: string = this.sharedService.maTheme;

  constructor(private sharedService: SharedService) {
    this.subscribePoll['maThemeSubject'] = sharedService.maThemeSubject.subscribe(value => {
      this.maTheme = value;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    _.forEach(this.subscribePoll, (value: any, key: string) => {
      if (value) {
        this.subscribePoll[key].unsubscribe();
      }
    });
  }
}
