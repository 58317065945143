import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { SharedService } from '../../shared/services/shared.service';
import { StaffService } from '../../model/staff.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  maThemeModel: string = 'green';
  subscribePoll: any = {};
  showMobileMenu = false;

  constructor(public staffService: StaffService, public sharedService: SharedService) {
    this.subscribePoll['maThemeSubject'] = sharedService.maThemeSubject.subscribe(value => {
      this.maThemeModel = value;
    });
  }

  setTheme() {
    this.sharedService.setTheme(this.maThemeModel);
  }

  ngOnInit() {}

  ngOnDestroy() {
    _.forEach(this.subscribePoll, (value: any, key: string) => {
      if (value) {
        this.subscribePoll[key].unsubscribe();
      }
    });
  }
}
