import { GlobalService } from './global.service';

export class QuestionGroup {
  id: number;
  group_article: string;
  group_article_image_id: number;
  file_path: string;
  file_url: string;
  image_width: number;
  image_height: number;

  group_solution_type: string;
  group_solution_type_label: string;
  group_solution: string;
  group_solution_image_id: number;
  group_solution_file_path: string;
  group_solution_file_url: string;
  group_solution_image_height: number;
  group_solution_image_width: number;
  group_solution_video: string;
  group_solution_video_type: string;
  group_solution_video_type_label: string;

  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.file_path) {
      this.file_url = GlobalService.getCDNHost() + this.file_path;
    }

    if (this.group_solution_file_path) {
      this.group_solution_file_url = GlobalService.getCDNHost() + this.group_solution_file_path;
    }
  }
}
