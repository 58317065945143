import * as _ from 'lodash';
import { MarkingGuideCriteria } from './marking-guide-criteria';

export class MarkingGuide {
  id: number;
  marking_guide_name: string;
  total_score: number;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  marking_guide_criteria: MarkingGuideCriteria[];

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.marking_guide_criteria) {
      this.marking_guide_criteria = _.map(
        this.marking_guide_criteria,
        markingGuideCriteria => new MarkingGuideCriteria(markingGuideCriteria)
      );
      this.calculateTotalScore();
    } else {
      this.marking_guide_criteria = [];
    }
  }

  calculateTotalScore() {
    if (!this.marking_guide_criteria) {
      this.total_score = 0;
    }

    let totalScore = 0;
    _.map(this.marking_guide_criteria, markingGuideCriteria => {
      totalScore += +markingGuideCriteria.score;
    });

    this.total_score = totalScore;
  }
}
