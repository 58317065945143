export class QuestionStatistic {
  id: number;
  question_id: number;
  year: string;
  student_cnt: number;
  correct_answer_cnt: number;
  difficulty: number;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
