import { CourseSubject } from './course-subject';

export class Course {
  id: number;
  course_name: string;
  order_by: number;
  course_subject: CourseSubject[];
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
