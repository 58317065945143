import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SharedService {
  /**
   * Serializes the form element so it can be passed to the back end through the url.
   * The objects properties are the keys and the objects values are the values.
   * ex: { "a":1, "b":2, "c":3 } would look like ?a=1&b=2&c=3
   *
   * @param obj
   * @returns string
   */
  public static serializeQueryString(obj: any): string {
    return Object.keys(obj)
      .map(k => `${k}=${encodeURIComponent(obj[k])}`)
      .join('&');
  }

  /**
   * Check attribute definition
   *
   * @param targetAttribute
   */
  public static isDefined(targetAttribute: any) {
    if (typeof targetAttribute !== 'undefined' && targetAttribute !== null) {
      return true;
    }
    return false;
  }
  // Sidebar visibility
  sidebarVisible: boolean;
  sidebarVisibilitySubject: Subject<boolean> = new Subject<boolean>();
  // Theming
  maTheme: string;
  maThemeSubject: Subject<string> = new Subject<string>();

  // Mobile sidebar state
  mobileSidebarActive: boolean = false;

  constructor() {
    // Hidden the sidebar by default
    this.sidebarVisible = false;

    // Set default theme as green
    this.maTheme = 'blue';
  }

  toggleSidebarVisibilty() {
    this.sidebarVisible = !this.sidebarVisible;
    this.sidebarVisibilitySubject.next(this.sidebarVisible);
  }

  setTheme(color) {
    this.maTheme = color;
    this.maThemeSubject.next(this.maTheme);
  }
}
