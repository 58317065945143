import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';

import * as _ from 'lodash';
import { SharedService } from '../shared/services/shared.service';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { StudentUser } from './student-user';

@Injectable()
export class StudentUserDataService {
  static getAllKey: string = 'get-all-student-users';
  static getKey: string = 'get-student-user';

  static getStatusTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 10
      },
      {
        label: 'Waiting Confirmation',
        value: 1
      },
      {
        label: 'Disabled',
        value: 0
      }
    ];
  }

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean = false;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/student-user
  getAllStudentUsers(extendedQueries?: any): Observable<StudentUser[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };

    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new StudentUser(value))),
        catchError(GlobalService.handleError)
      );
  }

  getStudentUserById(id: number): Observable<StudentUser> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/student-user/' + id, {
        headers: headers
      })
      .pipe(
        map(response => new StudentUser(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/student-user
  addStudentUser(studentUser: StudentUser): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/student-user', JSON.stringify(studentUser), {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/student-user/1
  updateStudentUserById(studentUser: StudentUser): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/student-user/' + studentUser.id, JSON.stringify(studentUser), {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/student-user/1
  deleteStudentUserById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/student-user/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/student-user';
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(StudentUserDataService.getAllKey)) {
        localStorage.removeItem(key);
      }

      if (key.startsWith(StudentUserDataService.getKey)) {
        localStorage.removeItem(key);
      }
    }
    this.onRemovedItems.next(true);
  }
}
