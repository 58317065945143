import { StudentUser } from './student-user';

export class Student {
  id: number;
  row_num: number;
  branch_id: number;
  branch_name: string;
  initial_grade_id: number;
  current_grade: string;
  student_code: string;
  student_name: string;
  current_school: string;
  dob: string;
  dob_utc: Date;
  parent_name: string;
  parent_nation: string;
  parent_address: string;
  parent_home_phone: string;
  parent_mobile_phone: string;
  parent_email_address: string;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  student_user: StudentUser;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (values['student_user'] !== undefined && values['student_user'] !== null) {
      Object.assign(this.student_user, new StudentUser(values['student_user']));
    } else {
      this.student_user = new StudentUser();
    }
  }
}
