<div class="container d-table">
  <div class="d-100vh-va-middle">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="clearfix">
          <h1 class="float-xs-left display-3 mr-2">404</h1>
          <h4 class="pt-1">Oops! You're lost.</h4>
          <p class="text-muted">The page you are looking for was not found.</p>
        </div>
      </div>
    </div>
  </div>
</div>
