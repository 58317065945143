import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { MarkingGuideCriteria } from './marking-guide-criteria';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { ResponseBody } from './response-body';
import { SharedService } from '../shared/services/shared.service';
import { MarkingGuideDataService } from './marking-guide-data.service';

@Injectable()
export class MarkingGuideCriteriaDataService {
  static getAllKey: string = 'get-all-marking-guide-criterias';

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  getURLList(id: number = null): string {
    if (id !== null) {
      return GlobalService.getAPIHost() + '/marking-guide/' + id + '/marking-guide-criteria';
    }
    return GlobalService.getAPIHost() + '/marking-guide-criteria';
  }

  // GET /v1/marking-guide/1/marking-guide-criteria
  getAllMarkingGuideCriterias(id: number, extendedQueries?: any): Observable<MarkingGuideCriteria[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: 'order_by'
    };
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList(id) + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new MarkingGuideCriteria(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/marking-guide/1/marking-guide-criteria/1
  getMarkingGuideCriteriaById(id: number, cid: number): Observable<MarkingGuideCriteria> {
    const headers = GlobalService.getHeaders();

    const url = this.getURLList(id) + '/' + cid;

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new MarkingGuideCriteria(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/marking-guide/1/marking-guide-criteria
  addMarkingGuideCriteria(id: number, markingGuideCriteria: MarkingGuideCriteria): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    const url = this.getURLList(id);

    return this.http
      .post<ResponseBody>(url, JSON.stringify(markingGuideCriteria), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/marking-guide/1/marking-guide-criteria/1
  updateMarkingGuideCriteriaById(id: number, markingGuideCriteria: MarkingGuideCriteria): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    const url = this.getURLList(id) + '/' + markingGuideCriteria.id;

    return this.http
      .put<ResponseBody>(url, JSON.stringify(markingGuideCriteria), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/marking-guide/1marking-guide-criteria/1
  deleteMarkingGuideCriteriaById(id: number, cid: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    const url = this.getURLList(id) + '/' + cid;

    return this.http
      .delete<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(MarkingGuideCriteriaDataService.getAllKey)) {
        localStorage.removeItem(key);
      }

      if (key.startsWith(MarkingGuideDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }
    this.onRemovedItems.next(true);
  }
}
