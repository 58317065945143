/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./404.component";
var styles_P404Component = [];
var RenderType_P404Component = i0.ɵcrt({ encapsulation: 2, styles: styles_P404Component, data: {} });
export { RenderType_P404Component as RenderType_P404Component };
export function View_P404Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "container d-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "d-100vh-va-middle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "col-md-6 offset-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [["class", "float-xs-left display-3 mr-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [["class", "pt-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Oops! You're lost."])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The page you are looking for was not found."]))], null, null); }
export function View_P404Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_P404Component_0, RenderType_P404Component)), i0.ɵdid(1, 49152, null, 0, i1.P404Component, [], null, null)], null, null); }
var P404ComponentNgFactory = i0.ɵccf("ng-component", i1.P404Component, View_P404Component_Host_0, {}, {}, []);
export { P404ComponentNgFactory as P404ComponentNgFactory };
