import { Component, OnInit, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SharedService } from '../../shared/services/shared.service';
import { StaffService } from '../../model/staff.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state(
        'inactive',
        style({
          height: '0',
          opacity: '0'
        })
      ),
      state(
        'active',
        style({
          height: '*',
          opacity: '1'
        })
      ),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ])
  ]
})
export class NavigationComponent implements OnInit, OnDestroy {
  public userData: any = {};
  subscribePoll: any = {};

  sidebarVisible: boolean;

  // Sub menu visibilities
  navigationSubState: any = {
    Settings: 'inactive'
  };

  constructor(public staffService: StaffService, public sharedService: SharedService, public router: Router) {
    this.subscribePoll['sidebarVisibilitySubject'] = sharedService.sidebarVisibilitySubject.subscribe(value => {
      this.sidebarVisible = value;
    });

    this.subscribePoll['routerEvent'] = router.events.subscribe(event => {
      if (event.constructor.name === 'NavigationStart') {
        if (this.sidebarVisible) {
          this.sharedService.toggleSidebarVisibilty();
        }
      }
    });
  }

  // Toggle sub menu
  toggleNavigationSub(menu, event) {
    event.preventDefault();
    this.navigationSubState[menu] = this.navigationSubState[menu] === 'inactive' ? 'active' : 'inactive';
  }

  ngOnInit() {
    const jwtValue: any = this.staffService.getJWTValue();
    this.userData = jwtValue.data;
  }
  ngOnDestroy() {
    _.forEach(this.subscribePoll, (value: any, key: string) => {
      if (value) {
        this.subscribePoll[key].unsubscribe();
      }
    });
  }
}
