export class StudentComment {
  id: number;
  student_id: number;
  comment_type: string;
  comment: string;
  status: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
