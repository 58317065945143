export class TestType {
  id: number;
  test_type: string;
  periodic_condition: number;
  order_by: number;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
