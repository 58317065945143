import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Classroom } from './classroom';

import { ClassStudent } from './class-student';
import { ClassStudentRole } from './class-student-role';
import { ClassHomework } from './class-homework';
import { ClassHomeworkForm } from './class-homework-form';
import { ClassTestForm } from './class-test-form';
import { ClassTest } from './class-test';
import { ClassTestStudentModule, ClassTestStudentModuleTerm } from './class-test-student-module';
import { SharedService } from '../shared/services/shared.service';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { ClassTestStudent } from './class-test-student';

@Injectable()
export class ClassroomDataService {
  static getAllKey: string = 'get-all-classrooms';
  static getKey: string = 'get-classroom';
  static queryParamsKey: string = 'class-list-query-params';

  static getAllStudentKey: string = 'get-all-class-students';
  static getStudentKey: string = 'get-class-student';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  static getDiscountPercentages(): Array<any> {
    return [
      {
        label: '0%',
        value: 0
      },
      {
        label: '10% (-1 week)',
        value: 1
      },
      {
        label: '50% (-2.5 week)',
        value: 2.5
      },
      {
        label: '75% (-7.5 week)',
        value: 7.5
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {
    this.loading = false;
  }

  // GET /v1/classroom
  getAllClassrooms(extendedQueries?: any): Observable<Classroom[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '?' + SharedService.serializeQueryString(queries);
    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new Classroom(value))),
        catchError(GlobalService.handleError)
      );
  }

  getClassroomById(id: number): Observable<Classroom> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id, {
        headers: headers
      })
      .pipe(
        map(response => new Classroom(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/classroom
  addClassroom(classroom: Classroom): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(ClassroomDataService.getAllKey);

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/classroom', JSON.stringify(classroom), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1
  updateClassroomById(classroom: Classroom): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(ClassroomDataService.getAllKey);

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + classroom.id, JSON.stringify(classroom), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/classroom/1
  deleteClassroomById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(ClassroomDataService.getAllKey);

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/student
  getAllClassStudent(id: number): Observable<ClassStudent[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/student', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassStudent(value))),
        catchError(GlobalService.handleError)
      );
  }

  getClassStudentById(id: number, sid: number): Observable<ClassStudent> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/student/' + sid, {
        headers: headers
      })
      .pipe(
        map(response => new ClassStudent(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/refresh
  refreshClassroomById(id: number): Observable<any> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/refresh', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/classroom/1/student
  addClassStudent(id: number, classStudent: ClassStudent): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/student', JSON.stringify(classStudent), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1/student/1
  updateClassStudentById(id: number, classStudent: ClassStudent): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/student/' + classStudent.id,
        JSON.stringify(classStudent),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/classroom/1/student/1
  deleteClassStudentById(id: number, classStudent: ClassStudent): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/student/' + classStudent.id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/class-role
  getClassRoles(id: number): Observable<ClassStudentRole[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/class-role', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassStudentRole(value))),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1/class-role
  updateClassRoles(id: number, classStudentRoles: ClassStudentRole[]): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/class-role',
        JSON.stringify(classStudentRoles),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/class-homework
  getClassHomework(id: number): Observable<ClassHomework[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/class-homework', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassHomework(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/class-homework/1
  getClassHomeworkByModule(id: number, mid: number): Observable<ClassHomeworkForm> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/class-homework/' + mid, {
        headers: headers
      })
      .pipe(
        map(response => new ClassHomeworkForm(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1/class-homework/1
  updateClassHomeworkByModule(id: number, classHomeworkForm: ClassHomeworkForm): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/class-homework/' + classHomeworkForm.module_no,
        JSON.stringify(classHomeworkForm),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/class-test
  getClassTestsByTestType(id: number): Observable<ClassTestForm[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/class-test', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassTestForm(value))),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1/class-test
  updateClassTestsByTestType(id: number, classTestForms: ClassTestForm[]): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/class-test',
        JSON.stringify(classTestForms),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/class-test/1
  getClassTest(id: number, classTestID: number): Observable<ClassTest[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/classroom/' + id + '/class-test/' + classTestID, {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassTest(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/1/class-test/student/1/1
  getClassTestStudentByModule(id: number, testTypeID: number, moduleNo: number): Observable<ClassTestStudentModule[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/class-test/student/' + testTypeID + '/' + moduleNo,
        {
          headers: headers
        }
      )
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassTestStudentModule(value))),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1/class-test/student/1/1
  updateClassTestStudent(
    id: number,
    testTypeID: number,
    moduleNo: string,
    classTestStudent: ClassTestStudent
  ): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/class-test/student/' + testTypeID + '/' + moduleNo,
        JSON.stringify(classTestStudent),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/classroom/1/class-test/student/1/1
  updateClassTestStudentByModule(
    id: number,
    testTypeID: number,
    moduleNo: number,
    classTestStudentModuleTerm: ClassTestStudentModuleTerm
  ): Observable<ResponseBody> {
    const headers = GlobalService.getHeadersForFormData();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/classroom/' + id + '/class-test/student/' + testTypeID + '/' + moduleNo,
        classTestStudentModuleTerm.toFormData(),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/classroom/{classroom_id}/class-test/print/{test_type_id}/{module_no}/{student_id}
  printClassTestForClassTestStudent(
    id: number,
    testTypeID: number,
    moduleNo: number,
    studentID: number
  ): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(
        GlobalService.getAPIHost() +
          '/classroom/' +
          id +
          '/class-test/print/' +
          testTypeID +
          '/' +
          moduleNo +
          '/' +
          studentID,
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/classroom';
  }

  removeItems() {
    this.loading = true;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(ClassroomDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith(ClassroomDataService.getKey)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith(ClassroomDataService.getAllStudentKey)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith(ClassroomDataService.getStudentKey)) {
        localStorage.removeItem(key);
      }
    }
    this.onRemovedItems.next(true);
  }
}
