import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { PaymentGroupSet } from './payment-group-set';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { SharedService } from '../shared/services/shared.service';
import { PaymentGroupDataService } from './payment-group-data.service';

@Injectable()
export class PaymentGroupSetDataService {
  static getAllKey: string = 'get-all-payment-group-sets';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {
    this.loading = false;
  }

  getURLList(id: number = null): string {
    if (id !== null) {
      return GlobalService.getAPIHost() + '/payment-group/' + id + '/payment-group-set';
    }
    return GlobalService.getAPIHost() + '/payment-group-set';
  }

  // GET /v1/payment-group/1/payment-group-set
  getAllPaymentGroupSets(id: number, extendedQueries?: any): Observable<PaymentGroupSet[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList(id) + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new PaymentGroupSet(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/payment-group/1/payment-group-set/1
  getPaymentGroupSetById(id: number, pid: number): Observable<PaymentGroupSet> {
    const headers = GlobalService.getHeaders();

    const url = this.getURLList(id) + '/' + pid;

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new PaymentGroupSet(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/payment-group/1/payment-group-set
  addPaymentGroupSet(id: number, paymentGroupSet: PaymentGroupSet): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    const url = this.getURLList(id);

    return this.http
      .post<ResponseBody>(url, JSON.stringify(paymentGroupSet), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/payment-group/1/payment-group-set/1
  updatePaymentGroupSetById(id: number, paymentGroup: PaymentGroupSet): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    const url = this.getURLList(id) + '/' + paymentGroup.id;

    return this.http
      .put<ResponseBody>(url, JSON.stringify(paymentGroup), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/payment-group/1/payment-group-set/1
  deletePaymentGroupSetById(id: number, pid: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    const url = this.getURLList(id) + '/' + pid;

    return this.http
      .delete<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(PaymentGroupSetDataService.getAllKey)) {
        localStorage.removeItem(key);
      }

      if (key.startsWith(PaymentGroupDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }

    this.onRemovedItems.next(true);
  }
}
