import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Student } from './student';
import { StudentComment } from './student-comment';

import * as _ from 'lodash';
import { SharedService } from '../shared/services/shared.service';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { Classroom } from './classroom';
import { ClassTestStudent } from './class-test-student';

@Injectable()
export class StudentDataService {
  static getAllKey: string = 'get-all-students';
  static getKey: string = 'get-student';
  static queryParamsKey: string = 'student-list-query-params';

  static getStatusTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 10
      },
      {
        label: 'Waiting Confirmation',
        value: 1
      },
      {
        label: 'Disabled',
        value: 0
      }
    ];
  }

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean = false;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/student
  getAllStudents(extendedQueries?: any): Observable<Student[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };

    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new Student(value))),
        catchError(GlobalService.handleError)
      );
  }

  getStudentById(id: number): Observable<Student> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id, {
        headers: headers
      })
      .pipe(
        map(response => new Student(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/student
  addStudent(student: Student): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/student', JSON.stringify(student), {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/student/1
  updateStudentById(student: Student): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/student/' + student.id, JSON.stringify(student), {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/student/1
  deleteStudentById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/student';
  }

  // GET /v1/student/1/comment
  getAllStudentComment(id: number): Observable<StudentComment[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id + '/comment?sort=-id', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new StudentComment(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/student/1/comment/1
  getStudentCommentById(id: number, cid: number): Observable<StudentComment> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id + '/comment/' + cid, {
        headers: headers
      })
      .pipe(
        map(response => new StudentComment(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/student/1/comment
  addStudentComment(studentComment: StudentComment): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(StudentDataService.getAllKey);

    return this.http
      .post<ResponseBody>(
        GlobalService.getAPIHost() + '/student/' + studentComment.student_id + '/comment',
        JSON.stringify(studentComment),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/student/1/comment/1
  updateStudentCommentById(studentComment: StudentComment): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(StudentDataService.getAllKey);

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/student/' + studentComment.student_id + '/comment/' + studentComment.id,
        JSON.stringify(studentComment),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/student/1/comment/1
  deleteStudentCommentById(id: number, cid: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(StudentDataService.getAllKey);

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id + '/comment/' + cid, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/student/1/classroom
  getAllStudentClassroom(id: number): Observable<Classroom[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id + '/classroom', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new Classroom(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/student/1/class-test-student
  getAllStudentClassTestStudent(id: number): Observable<ClassTestStudent[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/student/' + id + '/class-test-student', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new ClassTestStudent(value))),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(StudentDataService.getAllKey)) {
        localStorage.removeItem(key);
      }

      if (key.startsWith(StudentDataService.getKey)) {
        localStorage.removeItem(key);
      }
    }
    this.onRemovedItems.next(true);
  }
}
