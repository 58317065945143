export class ClassStudent {
  id: number;
  classroom_id: number;
  student_id: number;
  student_name: string;
  current_grade: string;
  enroll_date: string;
  enroll_date_utc: Date;
  paid_weeks: number;
  discount_percentage: number;
  payment_due_formula: string;
  payment_due: number;
  paid_date: string;
  paid_date_utc: Date;
  payment_method_id: number;
  payment_method: string;
  paid_amount: number;
  reference_number: string;
  reference_note: string;
  drop_indicator: number;
  drop_date: string;
  drop_date_utc: Date;
  drop_reason: string;
  teacher_comment: string;
  term_feedback: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    if (typeof values['discount_percentage_label'] !== 'undefined') {
      delete values['discount_percentage_label'];
    }

    Object.assign(this, values);

    this.drop_indicator = +this.drop_indicator;
    this.discount_percentage = +this.discount_percentage;
  }

  get discount_percentage_label(): string {
    let value = '';
    switch (this.discount_percentage) {
      case 0:
        value = '0%';
        break;
      case 1:
        value = '10% (-1 week)';
        break;
      case 2.5:
        value = '50% (-2.5 weeks)';
        break;
      case 7.5:
        value = '75% (-7.5 weeks)';
        break;
      default:
        value = 'Unknown (' + this.discount_percentage.toString() + ')';
    }

    return value;
  }
}
