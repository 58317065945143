import { throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

@Injectable()
export class StaffService {
  redirectURL = '';
  loggedIn = false;

  constructor(
    public globalService: GlobalService,
    public router: Router,
    public jwtHelper: JwtHelperService,
    public http: HttpClient
  ) {
    this.loggedIn = this.isLoggedIn();
  }

  login(username, password) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http
      .post<ResponseBody>(
        GlobalService.getAPIHost() + '/staff/login',
        JSON.stringify({
          LoginForm: {
            username: username,
            password: password
          }
        }),
        { headers: headers }
      )
      .pipe(
        map(response => {
          if (response.success) {
            localStorage.setItem('backend-token', response.data.access_token);
            this.loggedIn = true;
          } else {
            localStorage.removeItem('backend-token');
            this.loggedIn = false;
          }
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  logout(): void {
    localStorage.removeItem('backend-token');
    this.loggedIn = false;
  }

  getRoles(): any {}

  getToken(): any {
    return localStorage.getItem(environment.tokenName);
  }

  unauthorizedAccess(error: any): void {
    this.logout();
    this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    return this.jwtHelper.isTokenExpired() !== true;
  }

  getJWTValue(): any {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      return this.jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  checkPermission(type: string): boolean {
    if (this.isLoggedIn() === false) {
      return false;
    }
    const jwtValue = this.getJWTValue();
    const userData = jwtValue.data;

    if (userData.permissions.length > 0) {
      for (let i = 0; i < userData.permissions.length; i++) {
        const permission = userData.permissions[i];

        if (type === permission['name'] && permission['checked'] === true) {
          return true;
        }
      }
    }

    return false;
  }

  checkPermissionForConfigurations(): boolean {
    if (this.isLoggedIn() === false) {
      return false;
    }

    const configurationPermissions = [
      'manageBranch',
      'manageTeacher',
      'manageTerm',
      'manageGrade',
      'manageCourse',
      'manageSubject',
      'manageTestType',
      'manageTestCategory',
      'managePaymentMethod',
      'manageSetting',
      'manageStaff'
    ];

    const jwtValue = this.getJWTValue();
    const userData = jwtValue.data;

    if (userData.permissions.length > 0) {
      for (let i = 0; i < userData.permissions.length; i++) {
        const permission = userData.permissions[i];

        if (
          _.find(configurationPermissions, function(configurationPermission) {
            return configurationPermission === permission['name'];
          }) === permission['name']
        ) {
          return true;
        }
      }
    }

    return false;
  }

  checkToken(): any {
    return !!localStorage.getItem(environment.tokenName);
  }

  handleError(response: any) {
    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return observableThrowError(errorMessage);
  }
}
