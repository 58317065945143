var TestMainCategory = /** @class */ (function () {
    function TestMainCategory(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    Object.defineProperty(TestMainCategory.prototype, "subject_name", {
        get: function () {
            return this.subject.subject_name;
        },
        enumerable: true,
        configurable: true
    });
    return TestMainCategory;
}());
export { TestMainCategory };
