import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { QuestionGroup } from './question-group';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class QuestionGroupDataService {
  static getAllKey: string = 'get-all-question-groups';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/question/question-group
  getAllQuestionGroups(): Observable<QuestionGroup[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/question/question-group?sort=-id', {
        headers: headers
      })
      .pipe(
        map(response => response.data),
        map(data => _.map(data, value => new QuestionGroup(value))),
        catchError(GlobalService.handleError)
      );
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/question/question-group';
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(QuestionGroupDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
