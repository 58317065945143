import { catchError, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Setting } from './setting';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class SettingDataService implements OnDestroy {
  static getAllKey: string = 'get-all-settings';

  static getMetaTypes(): Array<any> {
    return [
      {
        label: 'Select',
        value: 'select'
      },
      {
        label: 'Number',
        value: 'number'
      },
      {
        label: 'Text',
        value: 'text'
      }
    ];
  }

  static getIsPublicTypes(): Array<any> {
    return [
      {
        label: 'Public',
        value: 1
      },
      {
        label: 'Private',
        value: 0
      }
    ];
  }
  subscribePoll: any = {};

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}
  ngOnDestroy() {
    _.forEach(this.subscribePoll, (value: any, key: string) => {
      if (value) {
        this.subscribePoll[key].unsubscribe();
      }
    });
  }

  // GET /v1/setting
  getAllSettings(): Observable<Setting[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/setting?sort=meta_key', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new Setting(value))),
        catchError(GlobalService.handleError)
      );
  }

  getSettingById(id: number): Observable<Setting> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/setting/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Setting(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/setting
  addSetting(setting: Setting): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(SettingDataService.getAllKey);

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/setting', JSON.stringify(setting), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/setting/1
  updateSettingById(setting: Setting): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(SettingDataService.getAllKey);

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/setting/' + setting.id, JSON.stringify(setting), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/setting/1
  deleteSettingById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    localStorage.removeItem(SettingDataService.getAllKey);

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/setting/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  refreshGlobalSettings(): void {
    // get settings
    let globalSettings = GlobalService.loadGlobalSettingsFromLocalStorage();
    if (globalSettings === null) {
      globalSettings = [];
    }

    this.subscribePoll['getAllSettingsPublic'] = this.getAllSettingsPublic().subscribe(settings => {
      settings.forEach(setting => {
        switch (setting.meta_type) {
          case 'select':
          case 'text':
            globalSettings[setting.meta_key] = setting.meta_value;
            break;
          case 'number':
            globalSettings[setting.meta_key] = +setting.meta_value;
            break;
        }
      });
      localStorage.setItem('backend-setting', JSON.stringify(globalSettings));
    });
  }

  // GET /v1/setting/public
  getAllSettingsPublic(): Observable<Array<any>> {
    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/setting/public', {
        // headers: headers
      })
      .pipe(
        map(response => {
          return <Array<any>>response.data;
        }),
        catchError(GlobalService.handleError)
      );
  }
}
