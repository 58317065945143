import { ClassHomework } from './class-homework';
import { ClassHomeworkStudent } from './class-homework-student';

export class ClassHomeworkForm {
  module_no: number;
  homework_date: string;
  class_homework: ClassHomework[];
  students?: ClassHomeworkStudentGroup[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ClassHomeworkStudentGroup {
  student_id: number;
  student_name: string;
  student_code: string;
  class_homework_students: ClassHomeworkStudent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
