import { ClassTest } from './class-test';

export class ClassTestForm {
  test_type_id: number;
  test_type: string;
  periodic_condition: number;
  periodic_condition_label: string;
  test_dates: ClassTestFormTestDate[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ClassTestFormTestDate {
  expected_test_date: string;
  actual_test_date: string;
  module_no: number;
  class_tests: ClassTest[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
