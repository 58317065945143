import { ClassTestStudentTestQuestion } from './class-test-student-test-question';

export class ClassTestStudent {
  id: number;
  class_test_id: number;
  student_id: number;
  student_code: string;
  student_name: string;
  progress_status: string;
  override_test_date: string;
  access_expire_at: string;
  remaining_test_duration: string;
  class_test_student_test_questions?: ClassTestStudentTestQuestion[];
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  // Following fields available only if student_id filtered
  classroom_id: number;
  class_type: string;
  class_no: string;
  subject_id: number;
  subject_name: string;
  test_type_id: number;
  test_type: string;
  module_no: string;
  expected_test_date: string;
  actual_test_date: string;
  test_id: number;
  correct_answer_cnt: number;
  total_cnt: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
