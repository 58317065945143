import { Payment } from './payment';

export class PaymentHistory {
  id: number;
  payment_id: number;
  comment: string;
  changed_values: string;
  values: any;
  status: number;
  updated_at: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    this.values = [];
    let changedValues = {};
    try {
      changedValues = JSON.parse(this.changed_values);
    } catch (e) {}

    if (changedValues['old_attributes'] !== undefined && changedValues['new_attributes'] !== undefined) {
      Object.keys(changedValues['old_attributes']).forEach(key => {
        this.values.push({
          key: key,
          label: Payment.getLabel(key),
          old: changedValues['old_attributes'][key],
          new: changedValues['new_attributes'][key]
        });
      });
    }
  }
}
