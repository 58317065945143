import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {
  subscribePoll: any = {};
  maTheme: string = this.sharedService.maTheme;

  constructor(public sharedService: SharedService) {
    this.subscribePoll['maThemeSubject'] = sharedService.maThemeSubject.subscribe(value => {
      this.maTheme = value;
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    _.forEach(this.subscribePoll, (value: any, key: string) => {
      if (value) {
        this.subscribePoll[key].unsubscribe();
      }
    });
  }
}
