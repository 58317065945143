import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';

import * as _ from 'lodash';
import { SetType } from './set-type';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class SetTypeDataService {
  static getSetTypeAllKey: string = 'get-all-set-types';
  static getSearchOptionKey: string = 'get-all-set-types-search-options';

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/set-type
  getAllSetTypes(): Observable<SetType[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/set-type', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new SetType(value))),
        catchError(GlobalService.handleError)
      );
  }

  getSetTypeById(id: number): Observable<SetType> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/set-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new SetType(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/set-type
  addSetType(setType: SetType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/set-type', JSON.stringify(setType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/set-type/1
  updateSetTypeById(setType: SetType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/set-type/' + setType.id, JSON.stringify(setType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/set/1
  deleteSetTypeById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/set-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(SetTypeDataService.getSetTypeAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
