var ClassStudent = /** @class */ (function () {
    function ClassStudent(values) {
        if (values === void 0) { values = {}; }
        if (typeof values['discount_percentage_label'] !== 'undefined') {
            delete values['discount_percentage_label'];
        }
        Object.assign(this, values);
        this.drop_indicator = +this.drop_indicator;
        this.discount_percentage = +this.discount_percentage;
    }
    Object.defineProperty(ClassStudent.prototype, "discount_percentage_label", {
        get: function () {
            var value = '';
            switch (this.discount_percentage) {
                case 0:
                    value = '0%';
                    break;
                case 1:
                    value = '10% (-1 week)';
                    break;
                case 2.5:
                    value = '50% (-2.5 weeks)';
                    break;
                case 7.5:
                    value = '75% (-7.5 weeks)';
                    break;
                default:
                    value = 'Unknown (' + this.discount_percentage.toString() + ')';
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    return ClassStudent;
}());
export { ClassStudent };
