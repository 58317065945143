export class Classroom {
  id: number;
  row_num: number;
  term_id: number;
  term_type: string;
  term_start: string;
  term_finish: string;
  total_module_no: number;
  course_id: number;
  course_name: string;
  grade_id: number;
  grade: string;
  teacher_id: number;
  teacher_name: string;
  class_type: string;
  class_type_label: string;
  day: string;
  location: string;
  description: string;
  long_description: string;
  class_no: string;
  tuition_fee: number;
  material_fee: number;
  start_date: string;
  start_date_utc: Date;
  finish_date: string;
  finish_date_utc: Date;
  class_date_range: any[];
  early_bird_date: string;
  early_bird_date_utc: Date;
  module_no: number;
  start_time: string;
  start_time_utc: Date;
  finish_time: string;
  finish_time_utc: Date;
  enrolled_student_cnt: number;
  dropped_student_cnt: number;
  is_sample: number;
  access_allowed_days: number;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  // Following fields available only if student_id filtered
  enroll_date: string;
  paid_date: string;
  drop_indicator: number;
  drop_date: string;
  drop_reason: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    switch (this.class_type) {
      case 'offline':
        this.class_type_label = 'Offline';
        break;
      case 'online':
        this.class_type_label = 'Online';
        break;
    }
  }
}
