import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
// Routing Module
import { AppRoutingModule } from './app.routing';
// Layouts
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { UserComponent } from './layout/sidebar/user/user.component';
import { LayoutComponent } from './layout/layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { P404Component } from './pages/404/404.component';
import { HeaderComponent } from './layout/header/header.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { NavigationTriggerComponent } from './layout/navigation-trigger/navigation-trigger.component';
import { AuthGuard } from './model/auth.guard';
import { SharedModule } from './shared/shared.module';
import { SharedService } from './shared/services/shared.service';

import { JwtModule } from '@auth0/angular-jwt';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from '../environments/environment';

// Model & Services
import { GlobalService } from './model/global.service';
import { StaffService } from './model/staff.service';
import { StaffDataService } from './model/staff-data.service';
import { SettingDataService } from './model/setting-data.service';
import { BranchDataService } from './model/branch-data.service';
import { TeacherDataService } from './model/teacher-data.service';
import { TermDataService } from './model/term-data.service';
import { GradeDataService } from './model/grade-data.service';
import { SubjectDataService } from './model/subject-data.service';
import { CourseDataService } from './model/course-data.service';
import { TestTypeDataService } from './model/test-type-data.service';
import { TestMainCategoryDataService } from './model/test-main-category-data.service';
import { PaymentMethodDataService } from './model/payment-method-data.service';
import { StudentDataService } from './model/student-data.service';
import { StudentUserDataService } from './model/student-user-data.service';
import { QuestionDataService } from './model/question-data.service';
import { TestDataService } from './model/test-data.service';
import { ClassroomDataService } from './model/classroom-data.service';
import { PaymentGroupDataService } from './model/payment-group-data.service';
import { PaymentGroupSetDataService } from './model/payment-group-set-data.service';
import { PaymentDataService } from './model/payment-data.service';

import { QuestionGroupDataService } from './model/question-group-data.service';
import { TermTypeDataService } from './model/term-type-data.service';
import { SetTypeDataService } from './model/set-type-data.service';
import { TestDocumentTypeDataService } from './model/test-document-type-data.service';
import { GalleryDataService } from './model/gallery-data.service';
import { MarkingGuideDataService } from './model/marking-guide-data.service';
import { MarkingGuideCriteriaDataService } from './model/marking-guide-criteria-data.service';

export function tokenGetter() {
  return localStorage.getItem(environment.tokenName);
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SidebarComponent,
    LayoutComponent,
    SimpleLayoutComponent,
    P404Component,
    HeaderComponent,
    NavigationComponent,
    NavigationTriggerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.apiHost]
      }
    })
  ],
  providers: [
    SharedService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthGuard,
    GlobalService,
    StaffService,
    StaffDataService,
    SettingDataService,
    BranchDataService,
    TeacherDataService,
    TermDataService,
    TermTypeDataService,
    SetTypeDataService,
    GradeDataService,
    SubjectDataService,
    CourseDataService,
    TestTypeDataService,
    TestMainCategoryDataService,
    PaymentMethodDataService,
    StudentDataService,
    StudentUserDataService,
    QuestionDataService,
    QuestionGroupDataService,
    TestDataService,
    TestDocumentTypeDataService,
    ClassroomDataService,
    PaymentGroupDataService,
    PaymentGroupSetDataService,
    PaymentDataService,
    GalleryDataService,
    MarkingGuideDataService,
    MarkingGuideCriteriaDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
