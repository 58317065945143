import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class GlobalService {
  static getAPIHost(): string {
    let apiHost;
    if (window.location.hostname === 'sms.academicsuccess.com.au') {
      apiHost = '//api.academicsuccess.com.au/v1';
    } else if (window.location.hostname === 'preuni-backend.chrislee.kr') {
      apiHost = '//preuni-api.chrislee.kr/v1';
    } else {
      apiHost = '//api.preuni.local/v1';
    }

    return apiHost;
  }

  static getCDNHost(): string {
    let cdnHost;
    if (window.location.hostname === 'sms.academicsuccess.com.au') {
      cdnHost = '//api.academicsuccess.com.au';
    } else if (window.location.hostname === 'preuni-backend.chrislee.kr') {
      cdnHost = '//preuni-api.chrislee.kr';
    } else {
      cdnHost = '//api.preuni.local';
    }
    return cdnHost;
  }

  static getAPIHostBaseURL(): string {
    let apiHostBaseURL;
    if (window.location.hostname === 'sms.academicsuccess.com.au') {
      apiHostBaseURL = '//api.academicsuccess.com.au';
    } else if (window.location.hostname === 'preuni-backend.chrislee.kr') {
      apiHostBaseURL = '//preuni-api.chrislee.kr';
    } else {
      apiHostBaseURL = '//api.preuni.local';
    }
    return apiHostBaseURL;
  }

  static getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + GlobalService.getToken()
    });
  }

  static getHeadersForFormData(): HttpHeaders {
    return new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });
  }

  static getToken(): any {
    return localStorage.getItem(environment.tokenName);
  }

  static handleError(response: Response | any) {
    let errorMessage: any = {};
    console.error('handleError:response => ', response);
    // Connection error
    if (response.error && response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: { message: 'Sorry, there was a connection error occurred. Please try again.' }
      };
    } else if (response.error) {
      errorMessage = response.error;
    } else if (response.message) {
      errorMessage = { success: false, status: 0, data: { message: response.message } };
    } else if (typeof response === 'string') {
      errorMessage = {
        success: false,
        status: 0,
        data: { message: response }
      };
    }

    return throwError(errorMessage);
  }

  static loadGlobalSettingsFromLocalStorage(): any | null {
    if (localStorage.getItem('backend-setting') !== null) {
      return JSON.parse(localStorage.getItem('backend-setting'));
    }
    return null;
  }
  setting: any = {};

  constructor() {}
}
