export class Branch {
  id: number;
  branch_code: string;
  branch_name: string;
  branch_address: string;
  contact_person_name: string;
  contact_work_phone: string;
  contact_mobile_phone: string;
  next_student_code: string;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
