import { ClassRole } from './class-role';

export class ClassStudentRole {
  id: number;
  student_name: string;
  term_feedback: string;
  class_roles: ClassRole[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
