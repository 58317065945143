import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';

import { SettingDataService } from './model/setting-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(public settingDataService: SettingDataService) {
    setTheme('bs4');
    // get settings
    this.settingDataService.refreshGlobalSettings();
  }
}
