import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { StaffService } from '../../model/staff.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  host: {
    class: 'sidebar'
  },
  animations: [
    trigger('toggleSubMenu', [
      state(
        'inactive',
        style({
          height: '0',
          opacity: '0'
        })
      ),
      state(
        'active',
        style({
          height: '*',
          opacity: '1'
        })
      ),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  // Sub menu visibilities
  navigationSubState: any = {
    Settings: 'inactive'
  };

  constructor(public staffService: StaffService) {}

  /* Main Menu

     * title: Main menu title
     * icon: Menu icon from material-design-iconic-fonts. Please refer 'Icons' page for more details
     * route: Router link for page
     * sub: Sub menus
     * visibility: Property for animation. 'inactive' means the sub menu is hidden by default.

     */

  // Toggle sub menu
  toggleNavigationSub(menu, event) {
    event.preventDefault();
    this.navigationSubState[menu] = this.navigationSubState[menu] === 'inactive' ? 'active' : 'inactive';
  }

  ngOnInit() {}
}
