export class Payment {
  static getLabel(key) {
    const labels = {
      id: 'ID',
      row_num: 'Row #',
      uuid: 'UUID',
      student_user_id: 'Student User ID',
      student_name: 'Student Name',
      email: 'Email',
      payment_group_set_id: 'Payment Group Set ID',
      payment_method_id: 'Payment Method ID',
      payment_method_code: 'Payment Method Code',
      payment_method: 'Payment Method',
      classroom_id: 'Classroom ID',
      term_type: 'Term Type',
      class_no: 'Class #',
      grade_id: 'Grade ID',
      grade: 'Grade',
      course_id: 'Course ID',
      course_name: 'Course Name',
      test_document_type_id: 'Test Document Type ID',
      test_document_type_code: 'Test Document Type Code',
      test_document_type: 'Test Document Type',
      purchased_number_of_sets: 'Purchased Number of Sets',
      remaining_number_of_sets: 'Remaining Number of Sets',
      price: 'Price',
      currency: 'Currency',
      purchased_ip: 'Purchased IP',
      payment_status: 'Payment Status',
      payment_status_label: 'Payment Status Label',
      payment_gateway_response: 'Payment Gateway Response',
      payment_receipt: 'Payment Receipt',
      delivery_address: 'Delivery Address',
      enabled: 'Enabled',
      enabled_label: 'Enabled Label',
      status: 'Status',
      created_at: 'Created At',
      created_by: 'Created By',
      updated_at: 'Updated At',
      updated_by: 'Updated By'
    };
    return labels[key] !== undefined ? labels[key] : undefined;
  }
  id: number;
  row_num: number;
  uuid: string;
  student_user_id: number;
  student_name: string;
  email: string;
  payment_group_set_id: number;
  payment_method_id: number;
  payment_method_code: string;
  payment_method: string;
  classroom_id: number;
  term_type: string;
  class_no: string;
  grade_id: number;
  grade: string;
  course_id: number;
  course_name: string;
  test_document_type_id: number;
  test_document_type_code: string;
  test_document_type: string;
  purchased_number_of_sets: number;
  remaining_number_of_sets: number;
  price: number;
  currency: string;
  purchased_ip: string;
  payment_status: string;
  payment_status_label: string;
  payment_gateway_response: string;
  payment_receipt: string;
  delivery_address: string;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: number;
  created_by: number;
  updated_at: number;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
