import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { TestType } from './test-type';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class TestTypeDataService {
  static getAllKey: string = 'get-all-test-type';
  static getSearchOptionKey: string = 'get-all-test-type-search-option';

  static getPeriodicConditions(): Array<any> {
    return [
      {
        label: 'Once Every Term',
        value: 1
      },
      {
        label: 'Every Two Weeks',
        value: 2
      },
      {
        label: 'Every Week',
        value: 3
      }
    ];
  }

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/test-type
  getAllTestTypes(): Observable<TestType[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/test-type?sort=order_by', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new TestType(value))),
        catchError(GlobalService.handleError)
      );
  }

  getTestTypeById(id: number): Observable<TestType> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/test-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new TestType(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/test-type
  addTestType(testType: TestType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/test-type', JSON.stringify(testType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/test-type/1
  updateTestTypeById(testType: TestType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/test-type/' + testType.id, JSON.stringify(testType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/test-type/1
  deleteTestTypeById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/test-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(TestTypeDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
