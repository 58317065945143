import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
var StaffService = /** @class */ (function () {
    function StaffService(globalService, router, jwtHelper, http) {
        this.globalService = globalService;
        this.router = router;
        this.jwtHelper = jwtHelper;
        this.http = http;
        this.redirectURL = '';
        this.loggedIn = false;
        this.loggedIn = this.isLoggedIn();
    }
    StaffService.prototype.login = function (username, password) {
        var _this = this;
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http
            .post(GlobalService.getAPIHost() + '/staff/login', JSON.stringify({
            LoginForm: {
                username: username,
                password: password
            }
        }), { headers: headers })
            .pipe(map(function (response) {
            if (response.success) {
                localStorage.setItem('backend-token', response.data.access_token);
                _this.loggedIn = true;
            }
            else {
                localStorage.removeItem('backend-token');
                _this.loggedIn = false;
            }
            return response;
        }), catchError(GlobalService.handleError));
    };
    StaffService.prototype.logout = function () {
        localStorage.removeItem('backend-token');
        this.loggedIn = false;
    };
    StaffService.prototype.getRoles = function () { };
    StaffService.prototype.getToken = function () {
        return localStorage.getItem(environment.tokenName);
    };
    StaffService.prototype.unauthorizedAccess = function (error) {
        this.logout();
        this.router.navigate(['/login']);
    };
    StaffService.prototype.isLoggedIn = function () {
        return this.jwtHelper.isTokenExpired() !== true;
    };
    StaffService.prototype.getJWTValue = function () {
        if (this.isLoggedIn()) {
            var token = this.getToken();
            return this.jwtHelper.decodeToken(token);
        }
        else {
            return null;
        }
    };
    StaffService.prototype.checkPermission = function (type) {
        if (this.isLoggedIn() === false) {
            return false;
        }
        var jwtValue = this.getJWTValue();
        var userData = jwtValue.data;
        if (userData.permissions.length > 0) {
            for (var i = 0; i < userData.permissions.length; i++) {
                var permission = userData.permissions[i];
                if (type === permission['name'] && permission['checked'] === true) {
                    return true;
                }
            }
        }
        return false;
    };
    StaffService.prototype.checkPermissionForConfigurations = function () {
        if (this.isLoggedIn() === false) {
            return false;
        }
        var configurationPermissions = [
            'manageBranch',
            'manageTeacher',
            'manageTerm',
            'manageGrade',
            'manageCourse',
            'manageSubject',
            'manageTestType',
            'manageTestCategory',
            'managePaymentMethod',
            'manageSetting',
            'manageStaff'
        ];
        var jwtValue = this.getJWTValue();
        var userData = jwtValue.data;
        if (userData.permissions.length > 0) {
            var _loop_1 = function (i) {
                var permission = userData.permissions[i];
                if (_.find(configurationPermissions, function (configurationPermission) {
                    return configurationPermission === permission['name'];
                }) === permission['name']) {
                    return { value: true };
                }
            };
            for (var i = 0; i < userData.permissions.length; i++) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        return false;
    };
    StaffService.prototype.checkToken = function () {
        return !!localStorage.getItem(environment.tokenName);
    };
    StaffService.prototype.handleError = function (response) {
        var errorMessage = {};
        // Connection error
        if (response.error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there was a connection error occurred. Please try again.'
            };
        }
        else {
            errorMessage = response.error;
        }
        return observableThrowError(errorMessage);
    };
    return StaffService;
}());
export { StaffService };
