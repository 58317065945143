import { TestQuestion } from './test-question';
import * as moment from 'moment';
import { GlobalService } from './global.service';

export class Test {
  id: number;
  row_num: number;
  term_type_id: number;
  term_type: string;
  grade_id: number;
  grade: string;
  subject_id: number;
  subject_name: string;
  test_type_id: number;
  test_type: string;
  set_type_id: number;
  set_type: string;
  set_no: string;

  test_icon_file_id: number;
  test_icon_file_path: string;
  test_icon_file_url: string;
  test_icon_image_width: string;
  test_icon_image_height: number;

  test_duration: string;
  test_duration_min: number;
  question_cnt: number;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  test_questions: TestQuestion[];
  test_icon_file: File;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.test_duration) {
      this.test_duration_min = moment.duration(this.test_duration).asMinutes();
    } else {
      this.test_duration_min = null;
    }

    if (this.test_icon_file_path) {
      this.test_icon_file_url = GlobalService.getCDNHost() + this.test_icon_file_path;
    }

    if (this.test_questions === undefined) {
      this.test_questions = [];
    }
  }
}
