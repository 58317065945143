<ng-scrollbar shown="hover" compact="true">
  <sidebar-user></sidebar-user>

  <ul class="navigation">
    <li routerLinkActive="navigation__active">
      <a [routerLink]="['/dashboard']"><i class="zmdi zmdi-home"></i> Dashboard</a>
    </li>
    <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageClass')">
      <a [routerLink]="['/class']"><i class="zmdi zmdi-city zmdi-hc-fw"></i> Class</a>
    </li>
    <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageStudent')">
      <a [routerLink]="['/student']"><i class="zmdi zmdi-accounts-list-alt zmdi-hc-fw"></i> Student</a>
    </li>
    <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTest')">
      <a [routerLink]="['/test']"><i class="zmdi zmdi-collection-text zmdi-hc-fw"></i> Test</a>
    </li>
    <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageQuestion')">
      <a [routerLink]="['/question']"><i class="zmdi zmdi-book-image zmdi-hc-fw"></i> Question</a>
    </li>
    <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('managePayment')">
      <a [routerLink]="['/payment']"><i class="zmdi zmdi-card zmdi-hc-fw"></i> Payment</a>
    </li>
    <li
      routerLinkActive="navigation__sub--active"
      class="navigation__sub"
      *ngIf="staffService.checkPermissionForConfigurations()"
    >
      <a (click)="toggleNavigationSub('Settings', $event)"><i class="zmdi zmdi-widgets"></i> Configuration</a>

      <ul [@toggleSubMenu]="navigationSubState.Settings">
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageBranch')">
          <a [routerLink]="['/branch']">Branch</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTeacher')">
          <a [routerLink]="['/teacher']">Teacher</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTerm')">
          <a [routerLink]="['/term']">Term</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTerm')">
          <a [routerLink]="['/term-type']">Term Type</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageGrade')">
          <a [routerLink]="['/grade']">Grade</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageCourse')">
          <a [routerLink]="['/course']">Course</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageSubject')">
          <a [routerLink]="['/subject']">Subject</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTestType')">
          <a [routerLink]="['/test-type']">Test Type</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageSetType')">
          <a [routerLink]="['/set-type']">Set Type</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTestDocumentType')">
          <a [routerLink]="['/test-document-type']">Test Document Type</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageTestCategory')">
          <a [routerLink]="['/test-category']">Test Categories</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('managePaymentGroup')">
          <a [routerLink]="['/payment-group']">Payment Group</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('managePaymentMethod')">
          <a [routerLink]="['/payment-method']">Payment Method</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageGallery')">
          <a [routerLink]="['/gallery']">Gallery</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageMarkingGuide')">
          <a [routerLink]="['/marking-guide']">Marking Guide</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageSetting')">
          <a [routerLink]="['/setting']">Setting</a>
        </li>
        <li routerLinkActive="navigation__active" *ngIf="staffService.checkPermission('manageStaff')">
          <a [routerLink]="['/staff']">Staff</a>
        </li>
      </ul>
    </li>
  </ul>
</ng-scrollbar>
