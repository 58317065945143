import { TestQuestion } from './test-question';
import { ClassTestStudent } from './class-test-student';

export class ClassTest {
  id: number;
  classroom_id: number;
  class_no: string;
  subject_id: number;
  subject_name: string;
  test_type_id: number;
  test_type: string;
  expected_test_date: string;
  actual_test_date: string;
  actual_test_date_utc: Date;
  test_id: number;
  set_no: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  test_questions?: TestQuestion[];
  class_test_students?: ClassTestStudent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
