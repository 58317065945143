import * as _ from 'lodash';
import { ClassTestStudentTestQuestion } from './class-test-student-test-question';

export class ClassTestStudentModuleTerm {
  id: number;
  class_test_id: number;
  test_id: number;
  subject_id: number;
  subject_name: string;
  student_id: number;
  progress_status: string;
  override_test_date: string;
  access_expire_at: string;
  remaining_test_duration: string;
  class_test_result: number;
  question_list_layout: string;
  test_question_total?: number;
  test_question_score?: number;
  test_question_percentage?: number;
  class_test_student_test_question?: ClassTestStudentTestQuestion[];

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.class_test_student_test_question && this.class_test_student_test_question.length > 0) {
      this.class_test_student_test_question = this.class_test_student_test_question.map(
        test_question => new ClassTestStudentTestQuestion(test_question)
      );
    }

    this.calculateScores();
  }

  calculateScores() {
    if (!this.class_test_student_test_question) {
      this.test_question_score = null;
      this.test_question_total = null;
      this.test_question_percentage = null;

      return;
    }

    this.test_question_score = 0;
    this.test_question_total = 0;
    this.test_question_percentage = 0;

    if (this.class_test_student_test_question && this.class_test_student_test_question.length > 0) {
      this.class_test_student_test_question = this.class_test_student_test_question.map(testQuestion => {
        testQuestion.calculateScore();
        this.test_question_score = this.test_question_score + testQuestion.score;

        return testQuestion;
      });
      this.test_question_total = this.class_test_student_test_question.length;
    }

    if (this.test_question_total > 0) {
      this.test_question_percentage = (this.test_question_score / this.test_question_total) * 100;
    }
  }

  toFormData(): FormData {
    const formData: FormData = new FormData();

    let keys = [
      'id',
      'class_test_id',
      'test_id',
      'subject_id',
      'subject_name',
      'student_id',
      'progress_status',
      'override_test_date',
      'access_expire_at',
      'remaining_test_duration',
      'class_test_result',
      'test_question_total',
      'test_question_score'
    ];

    keys.forEach((key: string) => {
      if (this[key] !== undefined && this[key] !== null) {
        formData.append(key, this[key]);
      }
    });

    if (this.class_test_student_test_question && this.class_test_student_test_question.length > 0) {
      keys = [
        'id',
        'class_test_student_id',
        'test_question_id',
        'assigned_no',
        'test_sub_category_id',
        'test_main_category_id',
        'question_answer_type',
        'question_answer',
        'student_answer',
        'student_answer_essay_file_id',
        'student_answer_essay',
        'student_answer_score_file_id',
        'score',
        'score_detail',
        'status'
      ];

      // 'student_answer_essay_file',

      this.class_test_student_test_question.forEach((classTestStudentTestQuestion: any, i: number) => {
        // If score detail parsed is defined, then assign to score_detail
        if (classTestStudentTestQuestion.score_detail_parsed) {
          classTestStudentTestQuestion.score_detail = JSON.stringify(classTestStudentTestQuestion.score_detail_parsed);
        }

        keys.forEach((key: string) => {
          const prefix = `class_test_student_test_question[${i}][${key}]`;
          if (classTestStudentTestQuestion[key] !== undefined && classTestStudentTestQuestion[key] !== null) {
            formData.append(prefix, classTestStudentTestQuestion[key]);
          }
        });

        if (classTestStudentTestQuestion.student_answer_essay_file) {
          formData.append(
            `class_test_student_test_question[${i}][student_answer_essay_file]`,
            classTestStudentTestQuestion.student_answer_essay_file,
            classTestStudentTestQuestion.student_answer_essay_file.name
          );
        }

        if (classTestStudentTestQuestion.student_answer_score_file) {
          formData.append(
            `class_test_student_test_question[${i}][student_answer_score_file]`,
            classTestStudentTestQuestion.student_answer_score_file,
            classTestStudentTestQuestion.student_answer_score_file.name
          );
        }
      });
    }

    return formData;
  }
}

export class ClassTestStudentModule {
  class_test_student_id: number;
  student_id: number;
  student_code: string;
  student_name: string;
  classroom_id: number;
  test_type_id: number;
  module_no: string;
  class_type: string;

  class_test_student_last_term: ClassTestStudentModuleTerm[];
  class_test_student_current_term: ClassTestStudentModuleTerm[];

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.class_test_student_last_term && this.class_test_student_last_term.length > 0) {
      this.class_test_student_last_term = this.class_test_student_last_term.map(
        module_term => new ClassTestStudentModuleTerm(module_term)
      );
    }

    if (this.class_test_student_current_term && this.class_test_student_current_term.length > 0) {
      this.class_test_student_current_term = this.class_test_student_current_term.map(
        module_term => new ClassTestStudentModuleTerm(module_term)
      );
    }
  }
}
