import { Branch } from './branch';

export class Staff {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  branch_id: number;
  email: string;
  unconfirmed_email: string;
  password: string;
  role: number;
  role_label: string;
  last_login_at: string;
  last_login_ip: string;
  confirmed_at: string;
  confirmed_at_utc: Date;
  blocked_at: string;
  blocked_at_utc: Date;
  status: number;
  status_label: string;
  created_at: string;
  updated_at: string;

  branch: Branch;
  permissions: StaffPermission[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class StaffPermission {
  name: string;
  description: string;
  checked: boolean;
}
