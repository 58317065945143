export class PaymentMethod {
  id: number;
  payment_method_code: string;
  payment_method: string;
  instruction: string;
  configuration: string;
  after_payment_message: string;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
