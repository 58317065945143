import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Payment } from './payment';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { SharedService } from '../shared/services/shared.service';
import { PaymentHistory } from './payment-history';
import { PaymentClassTestStudent } from './payment-class-test-student';

@Injectable()
export class PaymentDataService {
  static getAllKey: string = 'get-all-payments';
  static getKey: string = 'get-payment';

  static getPaymentStatus(): Array<any> {
    return [
      {
        label: 'Pending',
        value: 'pending'
      },
      {
        label: 'Completed',
        value: 'completed'
      },
      {
        label: 'Cancelled',
        value: 'cancelled'
      },
      {
        label: 'Refunded',
        value: 'refunded'
      }
    ];
  }

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {
    this.loading = false;
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/payment';
  }

  // GET /v1/payment
  getAllPayments(extendedQueries?: any): Observable<Payment[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new Payment(value))),
        catchError(GlobalService.handleError)
      );
  }

  getPaymentById(id: number): Observable<Payment> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/payment/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Payment(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/payment
  addPayment(payment: Payment): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/payment', JSON.stringify(payment), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/payment/1
  updatePaymentById(payment: Payment): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/payment/' + payment.id, JSON.stringify(payment), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/payment/1
  deletePaymentById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/payment/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/payment/1/payment-history
  getAllPaymentHistories(id: number, extendedQueries?: any): Observable<PaymentHistory[]> {
    const headers = GlobalService.getHeaders();

    let queries = {};
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '/' + id + '/payment-history' + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new PaymentHistory(value))),
        catchError(GlobalService.handleError)
      );
  }

  // GET /v1/payment/1/class-test-student
  getAllPaymentClassTestStudents(id: number, extendedQueries?: any): Observable<PaymentClassTestStudent[]> {
    const headers = GlobalService.getHeaders();

    let queries = {};
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url =
      this.getURLList() + '/' + id + '/class-test-student' + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new PaymentClassTestStudent(value))),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(PaymentDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }
    this.onRemovedItems.next(true);
  }
}
