import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { TestMainCategory } from './test-main-category';
import { TestSubCategory } from './test-sub-category';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class TestMainCategoryDataService {
  static getAllKey: string = 'get-all-test-categories';
  static getSearchOptionKey: string = 'get-all-test-categories-search-option';

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/test-main-category
  getAllTestMainCategories(): Observable<TestMainCategory[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/test-main-category?sort=order_by', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new TestMainCategory(value))),
        catchError(GlobalService.handleError)
      );
  }

  getTestMainCategoryById(id: number): Observable<TestMainCategory> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/test-main-category/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new TestMainCategory(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/test-main-category
  addTestMainCategory(testMainCategory: TestMainCategory): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/test-main-category', JSON.stringify(testMainCategory), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/test-main-category/1
  updateTestMainCategoryById(testMainCategory: TestMainCategory): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/test-main-category/' + testMainCategory.id,
        JSON.stringify(testMainCategory),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/test-main-category/1
  deleteTestMainCategoryById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/test-main-category/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/test-main-category/1/assign-test-sub-category
  assignTestSubCategory(testSubCategory: TestSubCategory): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(
        GlobalService.getAPIHost() +
          '/test-main-category/' +
          testSubCategory.test_main_category_id +
          '/assign-test-sub-category',
        JSON.stringify(testSubCategory),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/test-main-category/1/delete-test-sub-category/1
  deleteTestSubCategory(testSubCategory: TestSubCategory): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(
        GlobalService.getAPIHost() +
          '/test-main-category/' +
          testSubCategory.test_main_category_id +
          '/delete-test-sub-category/' +
          testSubCategory.id,
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(TestMainCategoryDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
