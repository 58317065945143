export class Teacher {
  id: number;
  branch_id: number;
  branch_name: string;
  teacher_name: string;
  home_phone: string;
  mobile_phone: string;
  email_address: string;
  major: string;
  enabled: number;
  enabled_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
