export class Term {
  id: number;
  row_num: number;
  branch_id: number;
  branch_name: string;
  year: number;
  term_type_id: number;
  term: string;
  total_module_no: number;
  term_start: string;
  term_finish: string;
  term_range: any[];
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
