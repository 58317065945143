<div class="user">
  <div class="user__info" (click)="userMenu = !userMenu" [class.active]="userMenu">
    <img class="user__img" src="./assets/img/8.jpg" alt="" />
    <div class="user__name">{{ userData.fullName }}</div>
    <div class="user__email">{{ userData.roleLabel }}</div>
  </div>

  <div *ngIf="userMenu" [@toggleUserMenu]="userMenu" class="user__menu">
    <a class="dropdown-item" [routerLink]="['/logout']">Logout</a>
  </div>
</div>
