import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Nl2BrPipe } from './directives/nl2br.pipe';
import { LimitToPipe } from './directives/limit-to.pipe';
import { JoinPipe } from './directives/join.pipe';
import { SafeHtmlPipe } from './directives/safe-html.pipe';
import { NullTextPipe } from './directives/null-text.pipe';

import { AutosizeDirective } from './directives/autosize/autosize.directive';
import { AutosizeModule } from 'ngx-autosize';
import { NgxFileDropModule } from 'ngx-file-drop';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Select2Module } from 'ng2-select2';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';

import { MomentModule } from 'angular2-moment';
import { TextMaskModule } from 'angular2-text-mask';
import { enPreuni } from './components/bs-moment/en-preuni';
import { FileSaverModule } from 'ngx-filesaver';
import { ColorPickerModule } from 'ngx-color-picker';
import { SmartTableCustomRenderComponent } from './components/smart-table/smart-table-custom-render.component';

import { StudentSummaryComponent } from '../components/student-summary/student-summary.component';
import { HttpClientModule } from '@angular/common/http';

defineLocale('en-preuni', enPreuni);

@NgModule({
  declarations: [
    // Directives
    AutosizeDirective,
    LimitToPipe,
    Nl2BrPipe,
    JoinPipe,
    SafeHtmlPipe,
    NullTextPipe,

    // Components
    SmartTableCustomRenderComponent,
    StudentSummaryComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    Ng2SmartTableModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    Select2Module,
    MomentModule,
    TextMaskModule,
    FileSaverModule,
    AutosizeModule,
    HttpClientModule,
    ColorPickerModule,
    NgxFileDropModule
  ],
  exports: [
    CommonModule,

    // Directives
    AutosizeModule,
    AutosizeDirective,
    NgxFileDropModule,
    LimitToPipe,
    Nl2BrPipe,
    JoinPipe,
    SafeHtmlPipe,
    NullTextPipe,

    // Components
    SmartTableCustomRenderComponent,
    StudentSummaryComponent,
    TimepickerModule,

    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    Ng2SmartTableModule,
    TypeaheadModule,
    BsDatepickerModule,
    Select2Module,
    MomentModule,
    TextMaskModule,
    FileSaverModule,
    HttpClientModule,
    ColorPickerModule
  ]
})
export class SharedModule {}
