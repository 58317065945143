import * as _ from 'lodash';
import { GlobalService } from './global.service';
import { MarkingGuide } from './marking-guide';

export class ClassTestStudentTestQuestion {
  id: number;
  class_test_student_id: number;
  test_question_id: number;
  assigned_no: string;
  test_sub_category_id: number;
  sub_category: string;
  test_main_category_id: number;
  main_category: string;
  question_answer_type: string;
  question_answer: string;
  student_answer: string;
  student_answer_essay_file_id: number;
  student_answer_essay_file_url: string;
  student_answer_essay_file_path: string;
  student_answer_essay_file_name: string;
  student_answer_essay_file: File;
  student_answer_essay: string;

  student_answer_notification_file_id: number;
  student_answer_notification_file_url: string;
  student_answer_notification_file_path: string;
  student_answer_notification_file_name: string;

  student_answer_score_file_id: number;
  student_answer_score_file_url: string;
  student_answer_score_file_path: string;
  student_answer_score_file_name: string;
  student_answer_score_file: File;

  score: number;
  score_detail: string;
  score_detail_parsed: any;

  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  marking_guide: MarkingGuide;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.student_answer) {
      this.student_answer = this.student_answer.toUpperCase();
    }

    this.score = +this.score;

    if (this.score_detail && this.score_detail !== '') {
      this.score_detail_parsed = JSON.parse(this.score_detail);
    } else {
      this.score_detail_parsed = {};
    }

    if (this.student_answer_essay_file_path) {
      this.student_answer_essay_file_url = GlobalService.getCDNHost() + this.student_answer_essay_file_path;
    }

    if (this.student_answer_notification_file_path) {
      this.student_answer_notification_file_url =
        GlobalService.getCDNHost() + this.student_answer_notification_file_path;
    }

    if (this.student_answer_score_file_path) {
      this.student_answer_score_file_url = GlobalService.getCDNHost() + this.student_answer_score_file_path;
    }

    if (_.isEmpty(this.marking_guide) === false) {
      this.marking_guide = new MarkingGuide(this.marking_guide);
      this.setScoreDetailParsed();
    }
  }

  setScoreDetailParsed() {
    let resetScoreDetail = false;
    // Marking guide is not set, reset it.
    if (this.score_detail_parsed.marking_guide === undefined) {
      resetScoreDetail = true;
    }

    // Marking guide is set, but it is different. reset it
    if (this.score_detail_parsed.marking_guide !== undefined && this.marking_guide !== undefined) {
      if (this.score_detail_parsed.marking_guide.id !== this.marking_guide.id) {
        resetScoreDetail = true;
      } else {
        const sourceMarkingGuideCriteria = _.map(this.marking_guide.marking_guide_criteria, criteria => {
          return {
            updated_at: criteria.updated_at
          };
        });

        const targetMarkingGuideCriteria = _.map(
          this.score_detail_parsed.marking_guide.marking_guide_criteria,
          criteria => {
            return {
              updated_at: criteria.updated_at
            };
          }
        );

        if (_.isEqual(sourceMarkingGuideCriteria, targetMarkingGuideCriteria) === false) {
          resetScoreDetail = true;
        }
      }
    }

    if (resetScoreDetail) {
      this.score_detail_parsed = {
        marking_guide: this.marking_guide
      };
    } else {
      this.score_detail_parsed.marking_guide = new MarkingGuide(this.score_detail_parsed.marking_guide);
    }
  }

  calculateScore() {
    if (this.question_answer_type === 'single') {
      // For single, simply check student answer

      if (this.student_answer) {
        this.student_answer = this.student_answer.toUpperCase();
      }

      if (this.question_answer && this.student_answer === this.question_answer) {
        this.score = 1;
      }
    }
  }
}
