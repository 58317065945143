export class MarkingGuideCriteria {
  id: number;
  marking_guide_id: number;
  marking_guide_name: string;
  criteria_name: string;
  score: number;
  max_score: number;
  guide_description: string;
  order_by: number;
  enabled: number;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.score === undefined) {
      this.score = 0;
    }
  }
}
