import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Course } from './course';
import { CourseSubject } from './course-subject';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { ResponseBody } from './response-body';

@Injectable()
export class CourseDataService {
  static getAllKey: string = 'get-all-courses';
  static getSearchOptionKey: string = 'get-all-courses-search-option';
  static getAll2Key: string = 'get-all-courses-subjects';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/course
  getAllCourses(): Observable<Course[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/course?sort=order_by', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new Course(value))),
        catchError(GlobalService.handleError)
      );
  }

  getCourseById(id: number): Observable<Course> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/course/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new Course(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/course
  addCourse(course: Course): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/course', JSON.stringify(course), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/course/1
  updateCourseById(course: Course): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/course/' + course.id, JSON.stringify(course), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/course/1
  deleteCourseById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/course/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/course/1/assign-subject
  assignSubject(courseSubject: CourseSubject): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(
        GlobalService.getAPIHost() + '/course/' + courseSubject.course_id + '/assign-subject',
        JSON.stringify(courseSubject),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/course/1/delete-subject/1
  deleteSubject(courseSubject: CourseSubject): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(
        GlobalService.getAPIHost() +
          '/course/' +
          courseSubject.course_id +
          '/delete-subject/' +
          courseSubject.subject_id,
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(CourseDataService.getAllKey)) {
        localStorage.removeItem(key);
      }

      if (key.startsWith(CourseDataService.getAll2Key)) {
        localStorage.removeItem(key);
      }
    }
  }
}
