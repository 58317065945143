import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { Test } from './test';

import * as _ from 'lodash';
import { SharedService } from '../shared/services/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class TestDataService {
  static getAllKey: string = 'get-all-tests';
  static getKey: string = 'get-test';
  static getViewKey: string = 'get-test-view';
  static queryParamsKey: string = 'test-list-query-params';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean = false;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/test
  getAllTests(extendedQueries?: any): Observable<Test[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };

    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new Test(value))),
        catchError(GlobalService.handleError)
      );
  }

  getTestById(id: number): Observable<Test> {
    const headers = GlobalService.getHeaders();

    const url = GlobalService.getAPIHost() + '/test/' + id;

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => new Test(response.data)),
        catchError(GlobalService.handleError)
      );
  }

  convertToFormData(test: Test) {
    const formData: FormData = new FormData();

    _.forIn(test, (value: any, key: string) => {
      if (key === 'test_icon_file') {
        return;
      }
      formData.append(key, value !== null ? value : '');
    });

    if (test.test_icon_file) {
      formData.append('test_icon_file', test.test_icon_file, test.test_icon_file.name);
    }

    return formData;
  }

  // POST /v1/test
  addTest(test: Test): Observable<ResponseBody> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const formData = this.convertToFormData(test);

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/test', formData, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/test/1
  updateTestById(test: Test): Observable<ResponseBody> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const formData = this.convertToFormData(test);

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/test/' + test.id, formData, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/test/1
  deleteTestById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/test/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          this.removeItems();
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/test';
  }

  saveQuestions(test: Test): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/test/' + test.id + '/question',
        JSON.stringify(test.test_questions),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          this.removeItems();

          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(TestDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith(TestDataService.getKey)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith(TestDataService.getViewKey)) {
        localStorage.removeItem(key);
      }
    }
    this.onRemovedItems.next(true);
  }
}
