import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { P404Component } from './pages/404/404.component';

import { AuthGuard } from './model/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'class',
        loadChildren: 'app/classroom/classroom.module#ClassroomModule'
      },
      {
        path: 'student',
        loadChildren: 'app/student/student.module#StudentModule'
      },
      {
        path: 'test',
        loadChildren: 'app/test/test.module#TestModule'
      },
      {
        path: 'question',
        loadChildren: 'app/question/question.module#QuestionModule'
      },
      {
        path: 'branch',
        loadChildren: 'app/branch/branch.module#BranchModule'
      },
      {
        path: 'teacher',
        loadChildren: 'app/teacher/teacher.module#TeacherModule'
      },
      {
        path: 'term',
        loadChildren: 'app/term/term.module#TermModule'
      },
      {
        path: 'term-type',
        loadChildren: 'app/term-type/term-type.module#TermTypeModule'
      },
      {
        path: 'grade',
        loadChildren: 'app/grade/grade.module#GradeModule'
      },
      {
        path: 'course',
        loadChildren: 'app/course/course.module#CourseModule'
      },
      {
        path: 'subject',
        loadChildren: 'app/subject/subject.module#SubjectModule'
      },
      {
        path: 'test-type',
        loadChildren: 'app/test-type/test-type.module#TestTypeModule'
      },
      {
        path: 'set-type',
        loadChildren: 'app/set-type/set-type.module#SetTypeModule'
      },
      {
        path: 'test-category',
        loadChildren: 'app/test-category/test-category.module#TestCategoryModule'
      },
      {
        path: 'test-document-type',
        loadChildren: 'app/test-document-type/test-document-type.module#TestDocumentTypeModule'
      },
      {
        path: 'payment-method',
        loadChildren: 'app/payment-method/payment-method.module#PaymentMethodModule'
      },
      {
        path: 'payment-group',
        loadChildren: 'app/payment-group/payment-group.module#PaymentGroupModule'
      },
      {
        path: 'payment',
        loadChildren: 'app/payment/payment.module#PaymentModule'
      },
      {
        path: 'gallery',
        loadChildren: 'app/gallery/gallery.module#GalleryModule'
      },
      {
        path: 'marking-guide',
        loadChildren: 'app/marking-guide/marking-guide.module#MarkingGuideModule'
      },
      {
        path: 'setting',
        loadChildren: 'app/setting/setting.module#SettingModule'
      },
      {
        path: 'staff',
        loadChildren: 'app/staff/staff.module#StaffModule'
      }
    ]
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: 'app/login/login.module#LoginModule'
      },
      {
        path: 'logout',
        loadChildren: 'app/logout/logout.module#LogoutModule'
      }
    ]
  },
  // otherwise redirect to home
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
