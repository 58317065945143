import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';

import * as _ from 'lodash';
import { TestDocumentType } from './test-document-type';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class TestDocumentTypeDataService {
  static getAllKey: string = 'get-all-test-document-types';
  static getSearchOptionKey: string = 'get-all-test-document-types-search-options';
  static getKey: string = 'get-test-document-type';

  constructor(public globalService: GlobalService, public http: HttpClient) {}

  getAllTestDocumentTypes(): Observable<TestDocumentType[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/test-document-type', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new TestDocumentType(value))),
        catchError(GlobalService.handleError)
      );
  }

  getTestDocumentTypeById(id: number): Observable<TestDocumentType> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/test-document-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new TestDocumentType(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  addTestDocumentType(testDocumentType: TestDocumentType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/test-document-type', JSON.stringify(testDocumentType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  updateTestDocumentTypeById(testDocumentType: TestDocumentType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/test-document-type/' + testDocumentType.id,
        JSON.stringify(testDocumentType),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  deleteTestDocumentTypeById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/test-document-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(TestDocumentTypeDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
