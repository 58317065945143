<header class="header">
  <app-navigation-trigger></app-navigation-trigger>
  <div class="header__logo ">
    <h1><a [routerLink]="['/dashboard']">Academic Success</a></h1>
  </div>

  <ul class="top-nav">
    <li dropdown>
      <a dropdownToggle><i class="zmdi zmdi-apps"></i></a>

      <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu--block" role="menu">
        <div class="row app-shortcuts">
          <a
            class="col-4 app-shortcuts__item"
            [routerLink]="['/class']"
            *ngIf="staffService.checkPermission('manageClass')"
          >
            <i class="zmdi zmdi-city zmdi-hc-fw bg-red"></i>
            <small class="">Classes</small>
          </a>
          <a
            class="col-4 app-shortcuts__item"
            [routerLink]="['/student']"
            *ngIf="staffService.checkPermission('manageStudent')"
          >
            <i class="zmdi zmdi-accounts-list-alt zmdi-hc-fw bg-blue-grey"></i>
            <small class="">Students</small>
          </a>
          <a
            class="col-4 app-shortcuts__item"
            [routerLink]="['/test']"
            *ngIf="staffService.checkPermission('manageTest')"
          >
            <i class="zmdi zmdi-assignment zmdi-hc-fw bg-orange"></i>
            <small class="">Tests</small>
          </a>
          <a
            class="col-4 app-shortcuts__item"
            [routerLink]="['/question']"
            *ngIf="staffService.checkPermission('manageQuestion')"
          >
            <i class="zmdi zmdi-collection-text zmdi-hc-fw  bg-light-green"></i>
            <small class="">Questions</small>
          </a>
          <a
            class="col-4 app-shortcuts__item"
            [routerLink]="['/course']"
            *ngIf="staffService.checkPermission('manageCourse')"
          >
            <i class="zmdi zmdi-library zmdi-hc-fw bg-teal"></i>
            <small class="">Courses</small>
          </a>
          <a
            class="col-4 app-shortcuts__item"
            [routerLink]="['/subject']"
            *ngIf="staffService.checkPermission('manageSubject')"
          >
            <i class="zmdi zmdi-library zmdi-hc-fw bg-blue"></i>
            <small class="">Subject</small>
          </a>
        </div>
      </div>
    </li>

    <li dropdown class="">
      <a dropdownToggle=""><i class="zmdi zmdi-more-vert"></i></a>

      <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu--icon" role="menu">
        <a [routerLink]="['/logout']" class="dropdown-item">Logout</a>
      </div>
    </li>
  </ul>
</header>
