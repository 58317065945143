import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';

import * as _ from 'lodash';
import { TermType } from './term-type';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';

@Injectable()
export class TermTypeDataService {
  static getTermTypeAllKey: string = 'get-all-term-types';

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {}

  // GET /v1/term
  getAllTermTypes(): Observable<TermType[]> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/term-type?sort=year,term_start', {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new TermType(value))),
        catchError(GlobalService.handleError)
      );
  }

  getTermTypeById(id: number): Observable<TermType> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/term-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new TermType(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/term
  addTermType(termType: TermType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/term-type', JSON.stringify(termType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/term/1
  updateTermTypeById(termType: TermType): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(GlobalService.getAPIHost() + '/term-type/' + termType.id, JSON.stringify(termType), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/term/1
  deleteTermTypeById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/term-type/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith(TermTypeDataService.getTermTypeAllKey)) {
        localStorage.removeItem(key);
      }
    }
  }
}
