import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { PaymentGroup } from './payment-group';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ResponseBody } from './response-body';
import { SharedService } from '../shared/services/shared.service';

@Injectable()
export class PaymentGroupDataService {
  static getAllKey: string = 'get-all-payment-groups';
  static getKey: string = 'get-payment-group';

  static getEnabledTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];
  }

  onRemovedItems = new Subject<boolean>();
  onRemovedItems$ = this.onRemovedItems.asObservable();

  loading: boolean;

  constructor(public globalService: GlobalService, public staffService: StaffService, public http: HttpClient) {
    this.loading = false;
  }

  getURLList(): string {
    return GlobalService.getAPIHost() + '/payment-group';
  }

  // GET /v1/payment-group
  getAllPaymentGroups(extendedQueries?: any): Observable<PaymentGroup[]> {
    const headers = GlobalService.getHeaders();

    let queries = {
      sort: '-id'
    };
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    const url = this.getURLList() + '?' + SharedService.serializeQueryString(queries);

    return this.http
      .get<ResponseBody>(url, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        map(data => _.map(data, value => new PaymentGroup(value))),
        catchError(GlobalService.handleError)
      );
  }

  getPaymentGroupById(id: number): Observable<PaymentGroup> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(GlobalService.getAPIHost() + '/payment-group/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return new PaymentGroup(response.data);
        }),
        catchError(GlobalService.handleError)
      );
  }

  // POST /v1/payment-group
  addPaymentGroup(paymentGroup: PaymentGroup): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .post<ResponseBody>(GlobalService.getAPIHost() + '/payment-group', JSON.stringify(paymentGroup), {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // PUT /v1/payment-group/1
  updatePaymentGroupById(paymentGroup: PaymentGroup): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .put<ResponseBody>(
        GlobalService.getAPIHost() + '/payment-group/' + paymentGroup.id,
        JSON.stringify(paymentGroup),
        {
          headers: headers
        }
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  // DELETE /v1/payment-group/1
  deletePaymentGroupById(id: number): Observable<ResponseBody> {
    const headers = GlobalService.getHeaders();

    this.removeItems();

    return this.http
      .delete<ResponseBody>(GlobalService.getAPIHost() + '/payment-group/' + id, {
        headers: headers
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError)
      );
  }

  removeItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(PaymentGroupDataService.getAllKey)) {
        localStorage.removeItem(key);
      }
    }

    this.onRemovedItems.next(true);
  }
}
