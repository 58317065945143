import { GlobalService } from './global.service';

export class Gallery {
  id: number;
  gallery_group: string;
  gallery_image_id: number;
  gallery_image_file_path: string;
  gallery_image_width: number;
  gallery_image_height: number;
  link_type: string;
  link_url: string;
  order_by: number;
  enabled: number;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  gallery_image_url: string;
  gallery_image: File;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.gallery_image_file_path) {
      this.gallery_image_url = GlobalService.getCDNHost() + this.gallery_image_file_path;
    }
  }
}
