export class PaymentClassTestStudent {
  id: number;
  payment_id: number;
  class_test_student_id: number;
  test_id: number;
  subject_id: number;
  subject_name: string;
  test_type_id: number;
  test_type: string;
  module_no: number;
  progress_status: string;
  override_test_date: string;
  access_expire_at: string;
  remaining_test_duration: string;
  status: number;
  created_at: number;
  created_by: number;
  updated_at: number;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
