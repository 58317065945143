export class TestDocumentType {
  id: number;
  test_document_type_code: string;
  test_document_type: string;
  status: number;
  created_at: string;
  updated_at: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
