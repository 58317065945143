export class ClassHomework {
  id: number; // class_homework_id
  classroom_id: number;
  module_no: number;
  course_subject_id: number;
  course_name: string;
  subject_name: string;
  homework_date: string;
  dominator: number;
  average_scores: number;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
