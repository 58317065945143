/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./header/header.component.ngfactory";
import * as i2 from "./header/header.component";
import * as i3 from "../model/staff.service";
import * as i4 from "../shared/services/shared.service";
import * as i5 from "./sidebar/sidebar.component.ngfactory";
import * as i6 from "./sidebar/sidebar.component";
import * as i7 from "./navigation/navigation.component.ngfactory";
import * as i8 from "./navigation/navigation.component";
import * as i9 from "@angular/router";
import * as i10 from "./layout.component";
var styles_LayoutComponent = [];
var RenderType_LayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "main", [["class", "main"]], [[1, "data-ma-theme", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(2, 245760, null, 0, i2.HeaderComponent, [i3.StaffService, i4.SharedService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-sidebar", [["class", "sidebar"]], [[2, "toggled", null]], null, null, i5.View_SidebarComponent_0, i5.RenderType_SidebarComponent)), i0.ɵdid(4, 114688, null, 0, i6.SidebarComponent, [i3.StaffService], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-navigation", [], null, null, null, i7.View_NavigationComponent_0, i7.RenderType_NavigationComponent)), i0.ɵdid(6, 245760, null, 0, i8.NavigationComponent, [i3.StaffService, i4.SharedService, i9.Router], null, null), (_l()(), i0.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(8, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maTheme; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.sharedService.mobileSidebarActive; _ck(_v, 3, 0, currVal_1); }); }
export function View_LayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i0.ɵdid(1, 245760, null, 0, i10.LayoutComponent, [i4.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i0.ɵccf("app-layout", i10.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
