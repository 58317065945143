<div
  class="navigation-trigger hidden-xl-up"
  (click)="openMobileSidebar()"
  [class.toggled]="sharedService.mobileSidebarActive"
>
  <div class="navigation-trigger__inner">
    <i class="navigation-trigger__line"></i>
    <i class="navigation-trigger__line"></i>
    <i class="navigation-trigger__line"></i>
  </div>
</div>
