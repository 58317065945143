import { QuestionStatistic } from './question-statistic';
import { GlobalService } from './global.service';

export class Question {
  static getDefaultAnswers() {
    return ['A', 'B', 'C', 'D', 'E'];
  }
  id: number;
  row_num: number;
  test_sub_category_id: number;
  test_sub_category: string;
  test_main_category_id: number;
  test_main_category: string;
  question_code: string;
  question_content: string;
  question_image_id: number;
  file_path: string;
  file_url: string;
  image_width: number;
  image_height: number;
  question_answer_type: string;
  question_answer_type_label: string;
  question_answers: string;
  question_answer: string;

  marking_guide_id: number;
  marking_guide_name: string;

  question_solution_type: string;
  question_solution_type_label: string;
  question_solution: string;
  question_solution_image_id: number;
  question_solution_file_path: string;
  question_solution_file_url: string;
  question_solution_image_width: string;
  question_solution_image_height: number;
  question_solution_video_type: string;
  question_solution_video_type_label: string;
  question_solution_video: string;

  question_group_id: number;
  question_group_article: string;
  question_group_article_image_id: number;
  question_group_file_path: string;
  question_group_file_url: string;
  question_group_image_width: number;
  question_group_image_height: number;

  question_group_solution_type: string;
  question_group_solution_type_label: string;
  question_group_solution: string;
  question_group_solution_image_id: number;
  question_group_solution_file_path: string;
  question_group_solution_file_url: string;
  question_group_solution_image_height: number;
  question_group_solution_image_width: number;
  question_group_solution_video_type: string;
  question_group_solution_video_type_label: string;
  question_group_solution_video: string;

  question_group_order_by: number;
  enabled: number;
  enable_label: string;
  status: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;

  question_image: File;
  question_group_article_image: File;

  question_statistics?: QuestionStatistic[];

  constructor(values: Object = {}) {
    this.question_image = null;

    Object.assign(this, values);

    if (!this.question_solution_type) {
      this.question_solution_type = 'text';
    }

    if (!this.question_solution_video_type) {
      this.question_solution_video_type = 'url';
    }

    if (!this.question_group_solution_type) {
      this.question_group_solution_type = 'text';
    }

    if (!this.question_group_solution_video_type) {
      this.question_group_solution_video_type = 'url';
    }

    if (!this.question_group_order_by) {
      this.question_group_order_by = 1;
    }

    if (!this.question_answers) {
      this.question_answers = JSON.stringify(Question.getDefaultAnswers());
    }

    if (this.file_path) {
      this.file_url = GlobalService.getCDNHost() + this.file_path;
    }

    if (this.question_group_file_path) {
      this.question_group_file_url = GlobalService.getCDNHost() + this.question_group_file_path;
    }

    if (this.question_solution_file_path) {
      this.question_solution_file_url = GlobalService.getCDNHost() + this.question_solution_file_path;
    }

    if (this.question_group_solution_file_path) {
      this.question_group_solution_file_url = GlobalService.getCDNHost() + this.question_group_solution_file_path;
    }
  }
}
